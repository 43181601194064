import { FunctionalComponent, h } from "preact";
import { RouterOnChangeArgs } from "preact-router";

import Home from "../routes/home";
import Header from "./header";
import Helmet from "preact-helmet";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
if ((module as any).hot) {
    // tslint:disable-next-line:no-var-requires
    require("preact/debug");
}

const App: FunctionalComponent = () => {
    let currentUrl: string;
    const handleRoute = (e: RouterOnChangeArgs) => {
        currentUrl = e.url;
    };

    return (
        <div id="app">
            <Helmet
                htmlAttributes={{ lang: "en", amp: undefined }} // amp takes no value
                title="Bai.Uno Navigation"
                meta={[
                    {
                        name: "description",
                        content:
                            "A navigator for bai.uno and a personal business card"
                    },
                    { property: "og:type", content: "article" }
                    // {
                    //     "http-equiv": "Content-Security-Policy",
                    //     content: "default-src *.bai.uno 'self'; object-src 'none'; img-src *;"
                    // }
                ]}
            />
            <Header />
            <Home />
            {/*<Router onChange={handleRoute}>*/}
            {/*    <Route path="/" component={Home} />*/}
            {/*    <Route path="/profile/" component={Profile} user="me" />*/}
            {/*    <Route path="/profile/:user" component={Profile} />*/}
            {/*    <NotFoundPage default />*/}
            {/*</Router>*/}
        </div>
    );
};

export default App;
